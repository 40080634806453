export const movies = {
    'BP': {
        name: 'Black Panther: Wakanda Forever',
        date: '2022-11-11',
        boxOffice: 453827135
    },
    'AT': {
        name: 'Armageddon Time',
        date: '2022-11-11',
        boxOffice: 1860050
    },
    'SS': {
        name: 'She Said',
        date: '2022-11-18',
        boxOffice: 5825995
    },
    'TM': {
        name: 'The Menu',
        date: '2022-11-18',
        boxOffice: 38501125
    },
    'BAA': {
        name: 'Bones and All',
        date: '2022-11-23',
        boxOffice: 7834907
    },
    'DEV': {
        name: 'Devotion',
        date: '2022-11-23',
        boxOffice: 20511938
    },
    'SW': {
        name: 'Strange World',
        date: '2022-11-23',
        boxOffice: 37968963
    },
    'TF': {
        name: 'The Fabelmans',
        date: '2022-11-23',
        boxOffice: 17340235
    },
    'VN': {
        name: 'Violent Night',
        date: '2022-12-02',
        boxOffice: 50015995
    },
    'HP': {
        name: 'House Party',
        date: '2023-01-13',
        boxOffice: 8953812
    },
    'TW': {
        name: 'The Whale',
        date: '2022-12-09',
        boxOffice: 17026533
    },
    'AMCO': {
        name: 'A Man Called Otto',
        date: '2022-12-23',
        boxOffice: 63989795
    },
    'ATWOW': {
        name: 'Avatar: The Way of Water',
        date: '2022-12-16',
        boxOffice: 675854315
    },
    'SA': {
        name: 'Spoiler Alert',
        date: '2022-12-16',
        boxOffice: 1384003
    },
    'IWDWS': {
        name: 'I Wanna Dance With Somebody',
        date: '2022-12-21',
        boxOffice: 23691439
    },
    'PIB': {
        name: 'Puss In Boots: The Last Wish',
        date: '2022-12-21',
        boxOffice: 180777025
    },
    'WT': {
        name: 'Women Talking',
        date: '2022-12-25',
        boxOffice: 5437691
    },
    'TH': {
        name: 'True Haunting',
        date: '2023-01-06',
    },
    'BAB': {
        name: 'Babylon',
        date: '2022-12-23',
        boxOffice: 15351455
    },
    'M3': {
        name: 'M3GAN',
        date: '2023-01-13',
        boxOffice: 95037390
    },
    'DIS': {
        name: 'Distant',
        date: '2023-01-27'
    },
    'TP': {
        name: 'Plane',
        date: '2023-01-13',
        boxOffice: 32111181
    },
    'KATC': {
        name: 'Knock at the Cabin',
        date: '2023-02-03',
        boxOffice: 35391100
    },
    '80': {
        name: '80 for Brady',
        date: '2023-02-03',
        boxOffice: 39187941
    },
    'IACBTM': {
        name: "It's All Coming Back To Me",
        date: '2023-02-10'
    },
    'MMLD': {
        name: "Magic Mike's Last Dance",
        date: '2023-02-10',
        boxOffice: 25942438
    },
    'AMATWQ': {
        name: "Ant-Man and the Wasp: Quantumania",
        date: '2023-02-17',
        boxOffice: 200992849
    },
    'CB': {
        name: "Cocaine Bear",
        date: '2023-02-24',
        boxOffice: 53978370
    },
    'JR': {
        name: "Jesus Revolution",
        date: '2023-02-24',
        boxOffice: 41508506
    },
    'MISS': {
        name: "Missing",
        date: '2023-01-20',
        boxOffice: 32446305

    },
    'C3': {
        name: "Creed III",
        date: '2023-03-03',
        boxOffice: 110049573
    },
    'DND': {
        name: "Dungeons & Dragons: Honor Among Thieves",
        date: '2023-03-31'
    },
    '65': {
        name: "65",
        date: '2023-03-10',
        boxOffice: 15752076
    },
    'SCR': {
        name: "Scream VI",
        date: '2023-03-10',
        boxOffice: 55743046
    },
    'HATPC': {
        name: "Harold and the Purple Crayon",
        date: '2023-06-30'
    }}
