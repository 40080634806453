import './App.css';
import { DataGrid } from '@mui/x-data-grid';
import {movies} from "./data/movies"
import {users} from "./data/users"
import {Button, Typography} from "@mui/material";
import {useState} from "react";
import CloseIcon from '@mui/icons-material/Close';

function App() {
    const [usePaulyScores, setUsePaulyScores] = useState(true)
    const [currentUser, setCurrentUser] = useState()

    const changeUser = function(user) {
        setCurrentUser(user)
        window.scrollTo(0, 0)
    }

    const userColumns = [
        { field: 'rank', headerName: 'Rank', minWidth: 40, flex: 1, type: 'number', },
        { field: 'user', headerName: 'User', minWidth: 90, flex: 2 },
        { field: 'points', headerName: 'Points', minWidth: 40, flex: 1, type: 'number' },
        { field: 'breakdown', headerName: 'Picks', minWidth: 120, flex: 1.5, renderCell: ({value}) => <BreakdownButton value={value} />, },
    ];

    const movieColumns = [
        { field: 'rank', headerName: 'Rank', minWidth: 75, flex: 1, type: 'number', },
        { field: 'movie', headerName: 'Movie', minWidth: 200, flex: 3 },
        { field: 'date', headerName: 'Release Date', minWidth: 100, flex: 2},
        {
            field: 'boxOffice',
            headerName: 'Box Office ($)',
            minWidth: 125, flex: 2, type: 'number',
            valueFormatter: ({ value }) => value ? value.toLocaleString() : 0
        },
    ];

    const pickColumns = [
        { field: 'movie', headerName: 'Movie', minWidth: 200, flex: 3.5 },
        { field: 'rank', headerName: 'Guessed Rank', minWidth: 100, flex: 1.5, type: 'number' },
        { field: 'realRank', headerName: 'Real Rank', minWidth: 100, flex: 1.5, type: 'number' },
        { field: 'points', headerName: 'Points', minWidth: 100, flex: 1.5, type: 'number' },
    ];

    const ranks = Object.entries(movies).map(([key,value]) => value.boxOffice || 0).sort((a,b) => b-a)

    const movieRows = Object.entries(movies).map(([key, value]) => ({
        id: key,
        movie: value.name,
        date: value.date,
        boxOffice: value.boxOffice || 0,
        rank: (ranks.indexOf(value.boxOffice || 0)+1),
    })).sort((a,b) => (b.boxOffice || 0)-(a.boxOffice || 0))

    const getMovieScore = function(movie,rank) {
        const guessedRank = parseInt(rank)
        const realRank = movieRows.find(movieRow => movieRow.id === movie)?.rank
        if (usePaulyScores) {
            if (guessedRank < 11) {
                const pointsForGuess = 21 - (guessedRank + (Math.abs(guessedRank - realRank) * 2))
                return pointsForGuess > 0 ? pointsForGuess : 0
            } else if (realRank < 11) {
                return 1
            } else {
                return 0
            }
        }
    }

    const pickRows = currentUser && Object.entries(users.find(user => user.user === currentUser)).filter(([key,value]) => key !== 'user').map(([key, value]) => ({
        id: key,
        rank: key < 11 ? key : 'DH',
        movie: movies[value]?.name,
        realRank: movieRows.find(movie => movie.id === value)?.rank,
        points: getMovieScore(value,key)
    }))

    const BreakdownButton = ({value}) => {
        return (
            <Button variant="contained" onClick={() => changeUser(value)}>See Picks</Button>
        );
    };

    const getUserScore = function(user) {
        let score = 0
        if (usePaulyScores) {
            Object.entries(user).forEach(([key, value]) => {
                const rank = parseInt(key)
                const realRank = movieRows.find(movie => movie.id === value)?.rank
                if (rank < 11) {
                    const pointsForGuess = 21 - (rank + (Math.abs(rank - realRank) * 2))
                    score += pointsForGuess > 0 ? pointsForGuess : 0
                } else if (realRank < 11) {
                    score += 1
                }
            })
        } else {

        }
        return score
    }

    let userRows = users.map((user) => ({
        id: user.user,
        user: user.user,
        points: getUserScore(user),
        breakdown: user.user
    }))

    const userRanks = userRows.map((user) => user.points || 0).sort((a,b) => b-a)

    userRows = userRows.map((user) => ({
        ...user,
        rank: (userRanks.indexOf(user.points || 0)+1)
    })).sort((a,b) => (b.points || 0)-(a.points || 0))

    return (
        <div className="App">
            <header className="App-header">
                <span>Slackfilmcast Winter Movie Wager</span><span className="lastUpdate">FINAL NUMBERS! Updated Mar 7th</span>
            </header>
            <div className="appBody" >
                {currentUser ?
                    <div className="tableContainer" style={{ height: 300 }}>
                        <Typography variant="h5" sx={{color: "#054D6D"}} gutterBottom>{currentUser}'s Picks <Button className='closeButton' onClick={() => changeUser(null)}><CloseIcon /></Button> </Typography>
                        <DataGrid
                            className="dataTable"
                            rows={pickRows}
                            columns={pickColumns}
                            stickyHeader={true}
                        />
                    </div>
                    :
                    <div className="tableContainer" style={{ height: 300 }}>
                    <Typography variant="h5" sx={{color: "#054D6D"}} gutterBottom>Movies</Typography>
                    <DataGrid
                        className="dataTable"
                        rows={movieRows}
                        columns={movieColumns}
                        stickyHeader={true}
                    />
                </div>}
                <div className="tableContainer" style={{ height: 300}}>
                    <Typography variant="h5" sx={{color: "#054D6D"}} gutterBottom>Users</Typography>
                    <DataGrid
                        className="dataTable"
                        rows={userRows}
                        columns={userColumns}
                        stickyHeader={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default App;
